import React from "react"

import Layout from "../components/layout"

const Success = () => {

  return (
    <Layout>
      <article>
        <h1>Thank You!</h1>
        <p>
          We've successfully recieved your message and will be in contact with
          you shortly
        </p>
      </article>
    </Layout>
  )
}

export default Success
